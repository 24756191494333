<template>
   <div>{{msg}}22222222222</div>
</template>
<script>
  export default {
      name: 'load',
      props: {
        msg: String
      }
 
  };
</script>